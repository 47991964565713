import React, { useEffect } from 'react';
import apiConfig from '../Config'; 

const LocationsByStatus = ({ data, phoneNumber }) => {
  useEffect(() => {
    if (!data || data.length === 0) {
      console.log('No hay data...');
      return; // No renderizar el mapa si no hay datos
    }

    console.log('Hay datos...');

    const map = new window.google.maps.Map(document.getElementById('MapLocationsJardin'), {
      center: { lat: parseFloat(data[0].latitud), lng: parseFloat(data[0].longitud) },
      zoom: 20,
      mapTypeId: 'satellite',
    });

    //let contador=0;
    //const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    //let labelIndex = 0;

    data.forEach((ubicacion) => {
      const lat = parseFloat(ubicacion.latitud);
      const lng = parseFloat(ubicacion.longitud);
      const codjardin = ubicacion.codjardin;
      const codarea = ubicacion.codarea;
      const codfraccion = ubicacion.codfraccion;

      const vshow = ubicacion.mostrar;

      let icon = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
      let titulo = ` ${ubicacion.jardin}, Area: ${ubicacion.codarea}, Fracción: ${ubicacion.codfraccion}`;
      if (vshow === 0) {
        titulo = `NO HAY UBICACIONES DISPONIBLES EN JARDIN ${ubicacion.jardin}`;
        icon = null;
      }

      const contentPopup = `
        <div class="info-window">
          <div class="info-window-header">
            <strong>${titulo}</strong>
          </div>
          <div class="info-window-content">
            <label for="email-input-${codarea}-${codfraccion}">Email/No. Whatsapp:</label>
            <input type="email" id="email-input-${codarea}-${codfraccion}" class="email-input" value="lato.com@gmail.com" placeholder="Ingrese su email/No Teléfono" required />
            <button id="reserve-btn-${codarea}-${codfraccion}" class="reserve-btn">Solicitar Reserva</button>
          </div>
        </div>
      `;

      
      const marker = new window.google.maps.Marker({
        position: { lat, lng },
        map: map,
        icon: icon,
        title: titulo,
        //label: labels[labelIndex++ % labels.length],
      });

      const infowindow = new window.google.maps.InfoWindow({
        content: contentPopup,
      });

      // Abrir el InfoWindow y esperar a que el DOM esté listo
      if (vshow === 1) {
        marker.addListener('click', () => {
          infowindow.open(map, marker);
  
          // Agregar evento cuando el InfoWindow esté completamente cargado (DOM listo)
          window.google.maps.event.addListenerOnce(infowindow, 'domready', () => {
            const reserveBtn = document.getElementById(`reserve-btn-${codarea}-${codfraccion}`);
            const emailInput = document.getElementById(`email-input-${codarea}-${codfraccion}`);
  
            if (reserveBtn) {
              reserveBtn.addEventListener('click', () => {
                const email = emailInput.value; // Capturar el valor del input de email
                
                if (validateEmailOrPhone(email)) {
                  solicitarReserva(codjardin, codarea, codfraccion, phoneNumber, email, titulo);

                  
                } else {
                  alert('Por favor ingrese un email válido o un número de teléfono con al menos 8 dígitos.');

                }
              });
            }
          });
        });
      }
      


      marker.setMap(map);
    });

     // Función para enviar la solicitud de reserva a la API
    const solicitarReserva = async (codjardin, codarea, codfraccion, phoneNumber, email, titulo) => {
      console.log('Solicitando reserva para:', codjardin, codarea, codfraccion, phoneNumber, email);
      if (!phoneNumber) {
        alert('No se ha identificado un número de teléfono.');
        return;
      }

      try {
        //const apiUrl = `${apiConfig.apiUrl}/get_locations_available`;
        const response = await fetch(`${apiConfig.apiUrl}/request_reservation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            codjardin,
            codarea,
            codfraccion,
            phoneNumber,
            email, // Enviamos el email junto con el resto de los parámetros
          }),
        });

        if (response.ok) {
          const result = await response.json();
          alert('Reserva solicitada con éxito: ' + result.message);

          if(validatePhone(email)){

            let numero_telefono = email;
            let parametros = `Reserva solicitada para: ${titulo} con numero de telefono: ${phoneNumber}`;

            const response2 = await fetch(`${apiConfig.apiUrl}/enviar_mensaje`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                numero_telefono,
                parametros 
              }),
            });
    
            if (response2.ok) {
              alert('Solicitud enviada a whatsapp: ' + numero_telefono);
            } else {
              alert('Error al solicitar envio whatsapp');
            }
          }

        } else {
          alert('Error al solicitar la reserva');
        }
 



      } catch (error) {
        console.error('Error en la solicitud de reserva:', error);
        alert('Error en la solicitud de reserva');
      }
    };

    
      // Función para validar el email o telefono
    const validateEmailOrPhone = (input) => {
      // Expresión regular para validar un email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // Expresión regular para validar un número de teléfono (solo números y al menos 8 dígitos)
      const phoneRegex = /^\d{8,}$/;
      // Comprobar si el input es un email o un número de teléfono válido
      return emailRegex.test(String(input).toLowerCase()) || phoneRegex.test(input);
    };

    const validatePhone = (input) => {
      const phoneRegex = /^\d{8,}$/;
      return phoneRegex.test(input);
    };


    
  }, [data, phoneNumber]);

 
 

  return data && data.length > 0 ? (
    <div id="MapLocationsJardin" style={{ width: '100%', height: '600px' }}></div>
  ) : null;

};

export default LocationsByStatus;
