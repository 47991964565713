import React from 'react'
import vangrupoLogo from '../assets/images/vangrupo-logo.svg'
import { Link } from 'react-router-dom';
import Legend from './Legend';

const SlidePromotions = () => {
    return (
        <Link to="/register"
        style={{
            textDecoration: 'none',  // Elimina el subrayado del texto
            outline: 'none',         // Elimina el contorno al enfocar
            cursor: 'pointer',       // Cambia el cursor al pasar el ratón
          }}
        >
            <div className="container-fluid vh-100 firs-bg">
                <div className="row align-items-center vh-100">
                    <div className="col-md-12 mx-auto my-auto text-center">
                        <h4 className='color-gray-60'>Bienvenido</h4>
                        <img src={vangrupoLogo} alt="Vangrupo Logo" height="500" width="500" />
                        <div className='box-for-text-sm bg-accent-color'>
                            <h4 className='color-gray-10'>Asistente Virtual</h4>
                        </div>
                    </div>
                <Legend></Legend>
                </div>
            </div>
        </Link>
    )
}

export default SlidePromotions
