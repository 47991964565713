import React from 'react';
import Card from './Card';  


const CardContainer = ({ cardData, mapData, escucharAudio, detenerAudio }) => {
  return (
    
      <div className="carousel-container" id="table-container" >
        {cardData.map((data, index) => (
          <Card key={index} data={data} escucharAudio={escucharAudio} detenerAudio={detenerAudio} />
        ))}
        
        
      </div>
    
  );
};

export default CardContainer;