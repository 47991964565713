// PhoneContext.js
import React, { createContext, useState, useContext } from 'react';

const PhoneContext = createContext();

export const PhoneProvider = ({ children }) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const setPhone = (newPhoneNumber) => {
    setPhoneNumber(newPhoneNumber);
  };

  return (
    <PhoneContext.Provider value={{ phoneNumber, setPhone }}>
      {children}
    </PhoneContext.Provider>
  );
};

export const usePhone = () => {
  const context = useContext(PhoneContext);
  if (!context) {
    throw new Error('usePhone must be used within a PhoneProvider');
  }
  return context;
};