import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'uikit/dist/css/uikit.css';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'

import './index.css';
import './App.css';
import './Login.css';

// Carga la API de Google Maps aquí
const script = document.createElement('script');
script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCGcZHrxPZSV9atebhnTyIAeJbJB97_LFc&libraries=places`;
script.async = true;
script.onload = () => {
  // Inicializa la aplicación de React solo después de cargar la API de Google Maps
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};
document.body.appendChild(script);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

UIkit.use(Icons);

