import React from 'react'

const legend = () => {
  return (
    <div>
        <div className='container w-60'>
            <div className='row'>
                <div className='col-12 text-center'>
                    <p className='color-gray-50 fs-06'>© 2023 Vangrupo. Todos los derechos reservados</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default legend
