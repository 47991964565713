import React, { useState} from 'react';
//import { useNavigate } from 'react-router-dom';
//import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import Legend from './Legend';
import Header from './Header';
import FloatingButton from './FloatingButton ';
import { usePhone } from './PhoneContext';
import apiConfig from '../Config'; 
import LocationsByStatus from './LocationsByStatus';

 
const AvailableLocations = () => {
  const { phoneNumber } = usePhone();
  const [mapData, setMapData] = useState([]); // Estado para almacenar las ubicaciones
  const [selectedJardin, setSelectedJardin] = useState('0'); // Estado para guardar el valor del jardín seleccionado


  const obtenerUbicacionesDisponibles = async () => {
    if (!phoneNumber) {
      //alert('No se ha identificado un número de teléfono.');
      //return;
    }
    let codjardin = document.getElementById('codjardin').value;

    try {
      const apiUrl = `${apiConfig.apiUrl}/get_locations_available`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          codjardin: codjardin,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const cantidadRegistros = data.datos.length;
        console.log('registros...', cantidadRegistros);
        
        console.log('Datos recibidos del servidor:', data); // Asegúrate de que los datos sean un array


        if (cantidadRegistros > 0) {
          console.log('datos...', data.datos[0].latitud);
          setMapData(data.datos); // Pasar todos los datos
        }
      } else {
        console.log('Error al guardar el archivo:', response.status);
      }
    } catch (error) {
      console.error('Error al guardar el archivo:', error);
    }

  };

   // Llamamos a la función obtenerUbicacionesDisponibles al cambiar la selección del jardín
   const handleJardinChange = (e) => {
    const jardinSeleccionado = e.target.value;
    setSelectedJardin(jardinSeleccionado);
    obtenerUbicacionesDisponibles(jardinSeleccionado); // Ejecutamos la función automáticamente al cambiar la selección
  };
  
  return (
    <div className=''>
            <div className="container-fluid">
                <div className="row align-items-center vh-100">
                    <div className="col-md-12 m-0 h-auto firs-bg">
                        <Header></Header>
                        <br/>
                        <div className='container bg-accent-color-20 form-survey'>
                            <div className='row'>
                                <div className='col-md-12 pt-3 text-center'>
                                    <h1 className='fs-12 color-accent'>Disponibilidad de Lotes</h1>
                                    <p className='fs-06 fw-300 color-gray-70'>Seleccione el jardin para ver ubicaciones disponibles</p>
                                    <br/>

                                </div>
                                <div className='col-md-12 pb-3'>
                                    <div className="form-group">
                                        
                                        <select 
                                          id='codjardin'
                                          className='form-control input-remake bg-accent-color-10 fs-06'
                                          value={selectedJardin} // Controlamos el valor a través del estado
                                          onChange={handleJardinChange} // Ejecutamos la función cuando se cambia la selección
                                        >
                                              <option value="0">Jardines...</option>
                                              <option value="1">LA ETERNIDAD II-1</option>
                                              <option value="2">LA ETERNIDAD II-2</option>
                                              <option value="3">LA ETERNIDAD I-A</option>
                                              <option value="4">LA ETERNIDAD I-B</option>
                                              <option value="5">LA ETERNIDAD C</option>
                                              <option value="6">EL REFUGIO</option>
                                              <option value="7">LOTE EL REFUGIO</option>
                                              <option value="8">LA ASCENCION</option>
                                              <option value="9">LA FE</option>
                                              <option value="10">LA FELICIDAD</option>
                                              <option value="11">EL MILAGRO</option>
                                              <option value="12">LA REFLEXION</option>
                                              <option value="13">LA ESPERANZA</option>
                                              <option value="14">EL EDEN</option>
                                              <option value="15">UBICACIONES TEMPORALES</option>
                                              <option value="16">LA ETERNIDAD D</option>
                                              <option value="17">LOS PIRINEOS</option>
                                              <option value="18">BELLA VISTA</option>
                                              <option value="19">LOS POETAS</option>
                                              <option value="20">LA PAZ</option>
                                              <option value="21">BELLO HORIZONTE</option>
                                              <option value="22">LAS VERANERAS</option>
                                              <option value="23">EL ROBLE 1</option>
                                              <option value="24">EL ROBLE 2</option>
                                              <option value="25">LAUREL 1</option>
                                              <option value="26">LAUREL 2</option>
                                              <option value="27">LOS PINOS 1</option>
                                              <option value="28">LOS PINOS 2</option>
                                        </select>
                                    </div>
                                </div>
                                
                                {
                                //<div className='col-md-12'>
                                //    <button className='btn button-remake btn-block' onClick={() => obtenerUbicacionesDisponibles()}><span className='fs-08'>Ver Mapa...</span></button>
                                //</div>
                                }

                                

                            </div>
                            
                        </div>
                        <br/>
                        {/* Componente para mostrar el mapa de Google con las ubicaciones */}
                        <div className="container" id="MapLocations">
                          <LocationsByStatus data={mapData} phoneNumber={phoneNumber} /> {/* Pasar los datos correctamente */}
                        </div>
                        

                    <Legend></Legend>
                    </div>
                </div>
            </div>
            <FloatingButton />
        </div>
  )
}

export default AvailableLocations
