import React from 'react';

const FloatingButton = () => {
  const handleGoBack = () => {
    window.history.back(); // Esto te lleva a la página anterior
  };

  return (
    <button className="floating-button bg-accent-color" onClick={handleGoBack}>
      <i className="bi bi-arrow-left-short fs-08"></i>
    </button>
  );
};

export default FloatingButton;
