import React, { useState,useEffect,useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Legend from './Legend';
import Header from './Header';
import ModalAlert from './ModalAlert';
import FloatingButton from './FloatingButton ';
import { usePhone } from './PhoneContext';
import AudioRecorder from "./RecordAudio";


const SearchDeceased = ({ escucharAudio,detenerAudio}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [countdown, setCountdown] = useState(55);
    
    const { phoneNumber } = usePhone();
    const navigate = useNavigate();


    

    const resetCountdown = () => {
        setCountdown(55);
      };

    const handleInteraction = useCallback(() => {
        resetCountdown();
    },[/* Dependencias de handleInteraction */])

    

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        openModal();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
    
        return () => clearInterval(timer);
      }, []);
    
      useEffect(() => {
        const interval = setInterval(() => {
          if (countdown === 0) {
            navigate('/');
          }
        }, 1000);
    
        return () => clearInterval(interval);
      }, [countdown, navigate]);
    
      useEffect(() => {
        // Agrega eventos de interacción del usuario
        window.addEventListener('mousemove', handleInteraction());
        window.addEventListener('keydown', handleInteraction());
        return () => {
          // Limpia los eventos cuando el componente se desmonta
          window.removeEventListener('mousemove', handleInteraction());
          window.removeEventListener('keydown', handleInteraction());
          resetCountdown();
        };
      }, [handleInteraction]);
    
      
    return (
        <div className=''>
            {/* firs-bg */}
            <div className="container-fluid">
                <div className="row align-items-center vh-100">
                    <div className="col-md-12 m-0 h-auto firs-bg text-center">
                        <Header></Header>

                        <p className='color-gray-70 fw-700 fs-02'>Por favor ingrese el nombre del fallecido o cliente en el cuadro buscador.  
                        <br/> (al tocar el cuadro se activará el micrófono).</p>
                        
                        
                        <ModalAlert isOpen={isModalOpen && !phoneNumber} onClose={closeModal}>
                            <br/>
                            <p className='color-accent fs-08 fw-400'>Para agilizar y optimizar su búsqueda, le recomendamos <br></br>que se <span className='fw-700'><a className="header-back-button" href='/register' > identifique </a> </span> proporcionando los datos solicitados. <br></br> Esto nos permitirá brindarle información de manera personalizada.</p>
                        </ModalAlert>
                         
                        <br/>
                        <div className='container w-60 position-relative'>
                            
                            
                        </div>
                        <AudioRecorder escucharAudio={escucharAudio} detenerAudio={detenerAudio} />
                        
                        <br/>
                        <h5 className='color-gray-70'>Nuestros servicios - Consulte</h5>
                        <br/>
                        <div className='container w-60'>
                            <div className='row justify-content-center'>
                                <div className='col-3 col-sm-3 col-md-3 align-self-start'>
                                    <Link to="/services">
                                        <div className='box-for-text-md bg-accent-color'>
                                            <h5 className='color-gray-10 fs-06'>
                                            <i className="bi bi-bookmark-check p-1"></i>
                                                Nuestros Productos
                                            </h5>
                                        </div>
                                    </Link>
                                    
                                </div>
                                <div className='col-2 col-sm-2 col-md-2 align-self-start'>
                                    
                                    <Link to="/survey">
                                        <div className='box-for-text-md bg-accent-color'>
                                            <h5 className='color-gray-10 fs-04'>
                                            <i className="bi bi-bookmark-check p-1"></i>
                                                Encuesta
                                            </h5>
                                        </div>
                                    </Link>
                                     
                                </div>
                                <div className='col-3 col-sm-3 col-md-3 align-self-start'>
                                     
                                    <Link to="/gift">
                                        <div className='box-for-text-md bg-accent-color'>
                                            <h5 className='color-gray-10 fs-06'>
                                            <i className="bi bi-bookmark-check p-1"></i>
                                                Obtener un Regalo!!!
                                            </h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    <Legend></Legend>
                    </div>
                </div>
            </div>
            <FloatingButton />
        </div>
    )
}

export default SearchDeceased
