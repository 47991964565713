import React from 'react'
import vangrupoLogo from '../assets/images/vangrupo-logo.svg'
import { usePhone } from './PhoneContext';
import { Link,useLocation  } from 'react-router-dom';


const Header = () => {
  const { phoneNumber } = usePhone();
  //const navigate = useNavigate();
  //const handleGoBack = () => {    navigate('/register');  };
  const location = useLocation();

  if (location.pathname === '/register') {
    return (
    
      <div className='container_image'>
      <img src={vangrupoLogo} className='vangrupo-logo' alt="Descripción de la imagen" />
      <div className='box-for-text-sm bg-accent-color'>
        <Link to="/"> 
          <h4 className='color-gray-10'>Asistente Virtual</h4>
        </Link>
      </div>
      
      <div className="header-right">
      </div>
      <br />
    </div>
    )
  }else{
    return (
      <div className='container_image'>
      <img src={vangrupoLogo} className='vangrupo-logo' alt="Descripción de la imagen" />
      <div className='box-for-text-sm bg-accent-color'>
        <Link to="/"> 
          <h4 className='color-gray-10'>Asistente Virtual</h4>
        </Link>
      </div>
      
      <div className="header-right">
      <Link to="/register"> 
        <h4 className='header-phone color-gray-6'>Identificacion: {phoneNumber}</h4>
      </Link>
      </div>
      <br />
    </div>
    )
  }

 
}

export default Header
