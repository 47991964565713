import React, { useState, useEffect } from 'react';
//import { ReactMic } from 'react-mic';
import 'uikit/dist/css/uikit.css';
import '../App.css';
import CardContainer from './CardContainer';
//import UbicacionMapa from './UbicacionMapa';
//import ModalVoz from './ModalVoz';
//import vangrupoLogo from '../assets/images/voz-logo.svg'
import apiConfig from '../Config'; // Asegúrate de ajustar la ruta correcta
 
const AudioRecorder = ({ escucharAudio,detenerAudio}) => {
  const [micActive, setMicActive] = useState(false); // Estado para controlar la visibilidad del micrófono
  const [isRecording, setIsRecording] = useState(false);
  const [audioData ] = useState('');
  const [cardData, setCardData] = useState([]);
  const [mapData, setMapData] = useState([]); // Estado para almacenar las ubicaciones
  
 
  let text = ""
  //const [isModalOpen1, setIsModalOpen1] = useState(false);

  
  const inputStyletextArea1 = {
    color: '#000', // Establece el color del texto
    padding: '8px', // Ajusta el espacio interno
    readonly: true,
    disabled: true,
    textAlign: 'center',
    border: 'none', 
    width: '100%', 
    resize: 'none',
    background: 'transparent'
  };

  //const micStyle = {    background: 'transparent',     border: 'none',     boxShadow: 'none',   };


  const startRecognition = (targetInput) => {
    setMicActive(true); 
    targetInput.value = ''; // Limpiar el contenido del input
    document.getElementById('result-textarea').value=''; // Limpiar el contenido del textarea
    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = 'es-ES';

    recognition.onresult = (event) => {
      const spoken = event.results[0][0].transcript;
      targetInput.value = spoken; // Establecer el valor del input con el texto reconocido
      //realizarBusquedaTexto(spoken)
      setMicActive(false); 
      document.getElementById('result-textarea').focus();


    };

    recognition.onend = () => {
        // Reiniciar el reconocimiento después de que termine
        if (recognition) {
            //recognition.start();
        }
    };
    

    recognition.start();

    targetInput.onblur = () => {
      recognition.stop();
    };

  };


  

  const handleMicButtonClick = () => {
    setIsRecording(!isRecording);
    setMicActive(!isRecording); // Mostrar el contenido del micrófono cuando se presiona el botón
  };
  
  
  //const handleSilence = () => {    setIsRecording(false);    console.log('Grabación detenida por silencio');  };

  
  


  


  const realizarBusquedaTexto = async (texto) => {
    setMicActive(false); 
    if (!texto) {
      return; // Detener la ejecución si el teléfono está vacío
    }

    if (texto.length < 6) {
      // Mostrar un mensaje de error si el texto tiene menos de 6 caracteres
      alert('El texto debe tener al menos 6 caracteres.');
        return;
    }
    const palabras = texto.trim().split(/\s+/); // Dividir el texto en palabras
    
    if (palabras.length < 2) {
        // Mostrar un mensaje de error si el texto tiene menos de dos palabras
        alert('El texto debe contener al menos dos palabras.');
        return;
    }


    try {
      const apiUrl = `${apiConfig.apiUrl}/busqueda_texto`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          texto: texto,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        let cantidadRegistros = data.datos.length;
        
        //const ubicaciones2 = [        { lat: 13.678855, lng: -89.147857, nombre: 'Ubicación 1' },        { lat: 13.678225, lng: -89.147152, nombre: 'Ubicación 2' },        { lat: 13.678525, lng: -89.147652, nombre: 'Ubicación 3' },        { lat: 13.678925, lng: -89.147352, nombre: 'Ubicación 4' },      ];
        //const result = 'En su Solicitud: "' +data.transcription+ '"... Se encontró el(los) nombre(s) de persona(s): ' + data.message;
        if (cantidadRegistros === 0) {
          text = "No se encontró ningún registro: " + data.message
        } else if (cantidadRegistros === 1) {
          text = "OK se encontró a " + data.message + "."
        } else {
          text = "Se encontró " + cantidadRegistros + " coincidencias para la busqueda de " + data.message
        }

        if (cantidadRegistros > 0) {
          setCardData(data.datos);
          setMapData(data.datos);

        }



        document.getElementById('sugerencias').value = data.transcription;
        document.getElementById('result-textarea').value = text

        const url_api = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiConfig.apiKey}`;
        const audioResponse = await fetch(url_api, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            input: {
              text,
            },
            voice: {
              languageCode: 'es-US',
              ssmlGender: 'FEMALE',
            },
            audioConfig: {
              audioEncoding: 'MP3',
            },
          }),
        });

        if (audioResponse.ok) {

          const audioData = await audioResponse.json();
          //const url = audioData.audioContent;
          const audioUrl = audioData.audioContent;
          //setAudioUrl(audioUrl);
          //playAudio();//escuchar automatica falta ver la asincronia
          //setAudioUrl(audioUrl);
          //playAudio(audioUrl);
          //handlePlayAudioInSearch(audioUrl) 
          escucharAudio(audioUrl);

        } else {
          console.log('Error al generar el audio:', audioResponse.status);
        }


      } else {
        console.log('Error al guardar el archivo:', response.status);
        // Aquí puedes manejar el error del guardado del archivo
      }
    } catch (error) {
      console.error('Error al guardar el archivo:', error);
      // Aquí puedes manejar el error del guardado del archivo
    }
  };



    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      const guardarAudio = async () => {
        const formData = new FormData();
        formData.append('audiosend', audioData, 'audio.wav');
    
        try {
    
          //const apiUrl = 'http://127.0.0.1:5000/v1/transcribir_audio'
          const apiUrl = `${apiConfig.apiUrl}/transcribir_audio`;
          const response = await fetch(apiUrl, {
            method: 'POST',
            body: formData,
          });
    
          if (response.ok) {
            const data = await response.json();
    
            let cantidadRegistros = data.datos.length;
            let text = ""
            if (cantidadRegistros === 0) {
              text = "No se encontró ningún registro: " + data.message
            } else if (cantidadRegistros === 1) {
              text = "OK se encontró a " + data.message + "."
            } else {
              text = "Se encontró " + cantidadRegistros + " coincidencias para la busqueda de " + data.message
            }
    
            if (cantidadRegistros > 0) {
              setCardData(data.datos);
              setMapData(data.datos);
    
            }
    
            document.getElementById('sugerencias').value = data.transcription;
            document.getElementById('result-textarea').value = text

            const url_api = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiConfig.apiKey}`;
            const audioResponse = await fetch(url_api, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                input: {
                  text,
                },
                voice: {
                  languageCode: 'es-US',
                  ssmlGender: 'FEMALE',
                },
                audioConfig: {
                  audioEncoding: 'MP3',
                },
              }),
            });
    
            if (audioResponse.ok) {
    
              const audioData = await audioResponse.json();
              const audioUrl = audioData.audioContent;
              escucharAudio(audioUrl);
    
            } else {
              console.log('Error al generar el audio:', audioResponse.status);
            }
    
          } else {
            console.log('Error al guardar el archivo:', response.status);
          }
        } catch (error) {
          console.error('Error al guardar el archivo:', error);
        }
      };

      let timerId;
      if (audioData) {
        guardarAudio();

        timerId = setTimeout(() => {
          setIsRecording(false);
        }, 2000);
      }
      return () => {
        clearTimeout(timerId);
      };
    }, [audioData,escucharAudio]);



  return (
    <div  >
      
      <input
        type='text'
        className='input-remake fs-08'
        placeholder='Toque aqui para ACTIVAR MICROFONO.'
        inputMode="text"
        id="sugerencias"
        onFocus={(e) => {
          startRecognition(e.target);
        }}
        onBlur={(e) => realizarBusquedaTexto(e.target.value)}
      />
      
      
      {micActive && (
        <div>
          <p className='fs-08 fw-400 color-accent'>Por favor, hablar ahora <br /> Escuchando...</p>
          <button
            className={`record-button recording round-button`}
            onClick={handleMicButtonClick}
          >
            <span
              uk-icon="icon: microphone; ratio: 4"
              className="uk-icon-large"
            ></span>
          </button>
        </div>
      )}

      
      
      {/*
       <div className="button-container">
        {isRecording ? (
          <div>
            <p className='fs-08 fw-400 color-accent'>Por favor, hablar ahora <br /> Escuchando...</p>
            <button
              className={`record-button recording round-button`}
              onClick={onStop}
              onTouchEnd={onStop}
            >
              <span
                uk-icon="icon: microphone; ratio: 4"
                className="uk-icon-large"
              ></span>
            </button>
            
          </div>
        ) : (
          <button
            className='button-remake fs-10'
            onClick={() => {
              onStart();
            }} onTouchStart={onStart}>
             
            Activar Microfono
          </button>
        )}
      </div> 
      */}
          

      {/*<span id='span_div' style={{ width: "100px", backgroundColor: "red", display: "none" }}>
        <ReactMic
          record={isRecording}
          className="sound-wave"
          onStop={onStop}
          onSilence={handleSilence}
          mimeType="audio/wav"
          style={micStyle}
        />
    </span>*/}
      <div className="pregunta-container">
        <textarea
          id="result-textarea"
          style={inputStyletextArea1}
          readOnly
        />
        
      </div>
      <div className="" >
        <div className=""   >
          <CardContainer cardData={cardData} mapData={mapData} escucharAudio={escucharAudio} detenerAudio={detenerAudio} />

        </div>
        
      </div>

    </div>
  );
};

export default AudioRecorder;
