import React,{useState} from 'react';
import { usePhone } from './PhoneContext';
import apiConfig from '../Config'; 
import location from '../assets/images/location.svg'
import whatsapp from '../assets/images/whatsapp.svg'
import mail from '../assets/images/mail.svg'
import print from '../assets/images/print.svg'
import play from '../assets/images/play.svg'
import UbicacionMapa2 from './UbicacionMapa2';


const Card = ({ data, escucharAudio, detenerAudio  }) => {
  const { phoneNumber } = usePhone();
  const [showMap, setShowMap] = useState(true);

  const { nombre_inhumado, codnegocio } = data;

    const handleShowLocation = () => {
        //const { lat, lng } = data;
        //const url = `https://www.google.com/maps?q=${lat},${lng}`;
        //window.open(url, '_blank');
        console.log(codnegocio); // Imprime: -74.0060
        if(showMap){
          setShowMap(null);
        }else{
          setShowMap(true);
        }
    };


      const handleSendWhatsApp = async () => {
        if (!phoneNumber) {
          alert('No se ha indentificado un numero de telefono.');
          return; // Detener la ejecución si el teléfono está vacío
        }
        data.telefono = phoneNumber;
        data.destino = 1;
        //const apiUrl = 'http://127.0.0.1:5000/v1/send_whatsapp_event'
        const apiUrl = `${apiConfig.apiUrl}/send_whatsapp_event`;
        const response = await fetch(apiUrl, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        
    
        if (response.ok) {
          // Aquí puedes manejar la respuesta exitosa
        } else {
          console.log('Error al enviar por WhatsApp:', response.status);
          // Aquí puedes manejar el error de envío por WhatsApp
        }
      };

      const handleSendEmail = async () => {
        if (!phoneNumber) {
          alert('No se ha indentificado un numero de telefono.');
          return; // Detener la ejecución si el teléfono está vacío
        }
        data.telefono = phoneNumber;
        data.destino = 2;
        //const apiUrl = 'http://127.0.0.1:5000/v1/send_whatsapp_event'
        const apiUrl = `${apiConfig.apiUrl}/send_whatsapp_event`;
        const response = await fetch(apiUrl, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        
    
        if (response.ok) {
          // Aquí puedes manejar la respuesta exitosa
        } else {
          console.log('Error al enviar por WhatsApp:', response.status);
          // Aquí puedes manejar el error de envío por WhatsApp
        }
      };

      const handlePrintCard = () => {
        const cardHtml = document.getElementById('card-content').innerHTML;
        const newWindow = window.open('', '_blank');
        newWindow.document.write(`
          <html>
            <head>
              <title>Imprimir</title>
            </head>
            <body>
              ${cardHtml}
            </body>
          </html>
        `);
        newWindow.document.close();
        newWindow.print();
      };

      const handleTextToSpeechCard = async (text) => {
          const url_api = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiConfig.apiKey}`;
          const audioResponse = await fetch(url_api, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              input: {
                text,
              },
              voice: {
                languageCode: 'es-US',
                ssmlGender: 'FEMALE',
              },
              audioConfig: {
                audioEncoding: 'MP3',
              },
            }),
          });
            
          if(audioResponse.ok){
            const audioData = await audioResponse.json();
            const audioUrl = audioData.audioContent;
             
            escucharAudio(audioUrl);                   
          
          }
        
      };

  


  return (
    <div className="custom-card"  >
      <div className='row' id='card-content'>
        <div className='col-3 text-start pt-2 pb-2 ffamily-sans-serif color-gray-6 fw-700'>
          <label>ID:</label>
        </div>
        <div className='col-8 text-start pt-2 pb-2'>
          <label className='fc-1 fw-400 ffamily-sans-serif color-gray-8 fw-600'>{data['codnegocio']}</label>
        </div>  
        <div className='col-3 text-start pt-2 pb-2 ffamily-sans-serif color-gray-6 fw-700'>
          <label>Cliente:</label>
        </div>
        <div  className='col-8 text-start pt-2 pb-2'>
          <label className='fc-1 fw-400 ffamily-sans-serif color-gray-8 fw-600'
              style={{
                wordWrap: 'break-word', // Para forzar el salto de línea dentro de palabras
                overflowWrap: 'break-word', // Para forzar el salto de línea entre palabras
                wordBreak: 'break-all', // Para permitir cortar palabras largas
                maxWidth: '100%', // Limitar el ancho máximo del contenedor
              }}
            
            >{data['nombre_cliente']}
          </label>
          
        </div>
        <div className='col-3 text-start pt-2 pb-2 ffamily-sans-serif color-gray-6 fw-700'>
          <label>Fallecido:</label>
        </div>
        <div className='col-8 text-start pt-2 pb-2'>
          <label className='fc-1 fw-400 ffamily-sans-serif color-gray-8 fw-600'
                        style={{
                          wordWrap: 'break-word', // Para forzar el salto de línea dentro de palabras
                          overflowWrap: 'break-word', // Para forzar el salto de línea entre palabras
                          wordBreak: 'break-all', // Para permitir cortar palabras largas
                          maxWidth: '100%', // Limitar el ancho máximo del contenedor
                        }}
          
          >{data['nombre_inhumado']}
          </label>
        </div>
        <div className='col-3 text-start pt-2 pb-2 ffamily-sans-serif color-gray-6 fw-700'>
          <label>Fecha:</label>
        </div>
        <div className='col-8 text-start pt-2 pb-2'>
          <label className='fc-1 fw-400 ffamily-sans-serif color-gray-8 fw-600'>{data['fecha_fallece']}</label>
        </div>
        <div className='col-3 text-start pt-2 pb-2 ffamily-sans-serif color-gray-6 fw-700'>
          <label>Jardín:</label>
        </div>
        <div className='col-8 text-start pt-2 pb-2'>
          <label className='fc-1 fw-400 ffamily-sans-serif color-gray-8 fw-600'>{data['nombre_jardin']}</label>
        </div>
        <div className='col-3 text-start pt-2 pb-2 ffamily-sans-serif color-gray-6 fw-700'>
          <label>Área:</label>
        </div>
        <div className='col-8 text-start pt-2 pb-2'>
          <label className='fc-1 fw-400 ffamily-sans-serif color-gray-8 fw-600'>{data['area']}</label>
        </div>
      </div> 
       
      
      <button hidden className="uk-button uk-button-default uk-button-small" onClick={handleShowLocation}>
        {/* <span uk-icon="icon: location; ratio: 1" ></span>  */}
        <img src={location} className='' alt=""/>
      </button>
      <button className="uk-button uk-button-default uk-button-small" onClick={handleSendWhatsApp}>
        {/* <span uk-icon="icon: whatsapp; ratio: 1" ></span>   */}
        <img src={whatsapp} className='' alt=""/>
      </button>
      <button hidden className="uk-button uk-button-default uk-button-small" onClick={handleSendEmail}>
      {/* <span uk-icon="icon: mail; ratio: 1" ></span>   */}
        <img src={mail} className='' alt=""/>
      </button>
      <button hidden className="uk-button uk-button-default uk-button-small"  onClick={handlePrintCard}>
        {/* <span uk-icon="icon: print; ratio: 1" ></span>  */}
        <img src={print} className='' alt=""/>
      </button>

      <button
          className="uk-button uk-button-default uk-button-small"
          onClick={() =>
            handleTextToSpeechCard("Cliente:"+data['nombre_cliente']+". fallecido:" +data['nombre_inhumado']+". en jardin :" +data['nombre_jardin'] + ". area: "+data['area']+". Fracción: "+data['fraccion'] )
          }
        >
          {/* <span uk-icon="icon: play-circle; ratio: 1"></span> */}
          <img src={play} className='' alt=""/>
      </button>

      <div id="mapContainer">
        {showMap && <UbicacionMapa2 lat={data['lat']} lng={data['lng']} ninhumado={nombre_inhumado} jardin={data['nombre_jardin']} namediv={data['codnegocio']+data['nombre_inhumado']} />}
      </div>
        ;


    </div>
  );
};

export default Card;