import React,{ useState, useEffect, useCallback } from 'react';
import Legend from './Legend';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import FloatingButton from './FloatingButton ';
import { usePhone } from './PhoneContext';
import apiConfig from '../Config'; // Asegúrate de ajustar la ruta correcta


// Importa tus imágenes
//import popcornImage from './images/popcorn.png';
//import keychainImage from './images/keychain.png';
//import balloonImage from './images/balloon.png';


const Gift = () => {
    //const [setIsModalOpen] = useState(false);
    const { phoneNumber } = usePhone();
    const [countdown, setCountdown] = useState(120);
    const navigate = useNavigate();

    const resetCountdown = useCallback(() => {
      setCountdown(120);
    }, []);
   

    
    const handleInteraction = useCallback(() => {
      resetCountdown();
    }, [resetCountdown]);
    
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        if ( document.getElementById('sugerencias').value==="" || document.getElementById('preg1').value==="" || document.getElementById('preg2').value==="" || document.getElementById('preg3').value==="") {
          alert('Por favor, responda todas las preguntas antes de enviar el formulario.');
          return;
        }
         
        if (!phoneNumber) {
            alert('No se ha indentificado un numero de telefono.');
            return; // Detener la ejecución si el teléfono está vacío
          }

        
        try {
          //const apiUrl = 'http://127.0.0.1:5000/v1/gift'
          const apiUrl = `${apiConfig.apiUrl}/save_gift`;
          const response = await fetch(apiUrl, {  
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              nombre: "noname",
              email: "nomail",
              telefono: phoneNumber,
              respuesta1: document.getElementById('preg1').value,
             }),
    
          });
    
          if (response.ok) {
            const data = await response.json();
            console.log('Datos de búsqueda:', data);
            document.getElementById('preg1').value=""
            document.getElementById('preg2').value=""
            document.getElementById('preg3').value=""
            document.getElementById('sugerencias').value=""
            
    
          } else {
            console.error('Error al guardar encuesta:', response.status);
          }
        } catch (error) {
          console.error('Error al guardar encuesta:', error);
        }
         
    
        
      };

      
      useEffect(() => {
        const timer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
    
        return () => clearInterval(timer);
      }, []);
    
      useEffect(() => {
        const interval = setInterval(() => {
          if (countdown === 0) {
            navigate('/');
          }
        }, 1000);
    
        return () => clearInterval(interval);
      }, [countdown, navigate]);
    
      useEffect(() => {
        // Agrega eventos de interacción del usuario
        window.addEventListener('mousemove', handleInteraction);
        window.addEventListener('keydown', handleInteraction);
    
        return () => {
          // Limpia los eventos cuando el componente se desmonta
          window.removeEventListener('mousemove', handleInteraction);
          window.removeEventListener('keydown', handleInteraction);
          resetCountdown();
        };
      }, [handleInteraction, resetCountdown]);

  
       



    //const openModal = () => {        setIsModalOpen(true);    };

    //const closeModal = () => {        setIsModalOpen(false);    };

    //useEffect(() => {        // openModal();    }, []);

  return (
    <div className=''>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-12 m-0 h-auto firs-bg">
                        <Header></Header>
                        <br/>
                        <div className='container bg-accent-color-20 form-survey'>
                            <div className='row'>
                                <div className='col-md-12 pt-3 text-center'>
                                    <h1 className='fs-12 color-accent'>Cupón de Regalo</h1>
                                    <p className='fs-06 fw-300 color-gray-70'>Seleccione la opcion de regalo y Obtenga un <br></br>CUPON CANJEABLE !!. Se le enviará a su Numero de WhatsApp <br/> 
                                            <br></br>Seleccione de la lista...
                                    </p>
                                    <br/>
                                </div>
                                {/*<div className='col-md-12 pb-3'>
                                    <img src={popcornImage} alt="Palomitas" onClick={() => handleGiftClick('Palomitas')} className="gift-image" />
                                    <img src={keychainImage} alt="Llavero" onClick={() => handleGiftClick('Llavero')} className="gift-image" />
                                    <img src={balloonImage} alt="Globo" onClick={() => handleGiftClick('Globo')} className="gift-image" />
                                </div>
                            */}
                                <div className='col-md-12 pb-3'>
                                    <div className="form-group">
                                        <label className='control-label fs-06 color-gray-70 fw-600'>
                                        </label>
                                        <select id='preg1'  className='form-control input-remake bg-accent-color-10 fs-06'>
                                        <option value="Regular">20% Descuento al Adquirir un Producto de Parque Memorial</option>
                                        <option value="Regular">50% Descuento en el pago de su primer cuota en cualquier plan</option>
                                        <option value="Regular">Etc...</option>
                                        <option value="Regular">Etc...</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div className='col-md-12 pb-3'>
                                    <div className="form-group">
                                        <label className='control-label fs-06 color-gray-70 fw-600'>
                                            <p className='fs-06 fw-300 color-gray-70'>*Restricciones Aplican.</p>
                                        </label>
                                         
                                    </div>
                                </div>
                                  
                                <div className='col-md-12'>
                                    <button className='btn button-remake btn-block' onClick={handleSubmit}><span className='fs-08'>Enviar</span></button>
                                    
                                </div>
                            </div>
                        </div>
                        <br/>
                    <Legend></Legend>
                    </div>
                </div>
            </div>
            <FloatingButton />
        </div>
  )
}

export default Gift