import React, { useState,useEffect, useRef  } from 'react';
import Legend from './Legend';
import Header from './Header';
import { json, useNavigate } from 'react-router-dom';
import ModalAlert from './ModalAlert';
import { usePhone } from './PhoneContext';
import apiConfig from '../Config'; // Asegúrate de ajustar la ruta correcta

const Register = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    //const [audioPlaying, setAudioPlaying] = useState(false);
    
    const { setPhone } = usePhone();
    const audioRef = useRef(new Audio());
    const navigate = useNavigate();
    //const [isRecording, setIsRecording] = useState(false);
    


    const openModal = () => {
        setIsModalOpen(true);
        playAudio();
    };

    const closeModal = () => {
        setIsModalOpen(false);
        stopAudio();
    };


    //let activeAudio = false;

    const playAudio = async (audioUrl) => {
        try {
            if (audioUrl) {
                const audio = audioRef.current;
                audio.src = `data:audio/mp3;base64,${audioUrl}`;
                await audio.play();
                //audio.onended = () => setAudioPlaying(false);
                //setAudioPlaying(true);
            }
        } catch (error) {
            // Maneja el error de carga de audio
            console.error("Error al reproducir el audio:", error);
        }
    };

    
    const stopAudio = () => {
        const audio = audioRef.current;
        audio.pause();
        audio.currentTime = 0;
        //setAudioPlaying(false);
    };

    


    useEffect(() => {
        //openModal();
    }, []);
    
     

    const startRecognition = (control,targetInput) => {
        const recognition = new window.webkitSpeechRecognition();
        recognition.lang = 'es-ES';
        
        recognition.onresult = (event) => {
            const spoken = event.results[0][0].transcript;
            targetInput.value = spoken; // Establecer el valor del input con el texto reconocido
        };

        recognition.onend = () => {
            // Iniciar el temporizador para avanzar al siguiente campo después de 2 segundos de silencio
            setTimeout(() => {
                //goToNextField(control); // Avanzar al siguiente campo después de 2 segundos de silencio
            }, 500);
        };
        
        recognition.start();
        //setIsRecording(true); // Establecer el estado de grabación como verdadero

        
        targetInput.onblur = () => {
            recognition.stop();
            //setIsRecording(false); // Establecer el estado de grabación como falso
        };
         
    };

   /* const goToNextField = (control) => {
        if (control === 1) {
            const nextField = document.getElementById('numero_telefono');
            nextField.focus(); // Dar foco al siguiente campo
        } else if (control === 2) {
            const nextField = document.getElementById('email_cliente');
            nextField.focus(); // Dar foco al siguiente campo
        }

      
        //startRecognition(nextField); // Iniciar reconocimiento de voz en el siguiente campo

    };    */


    const handleSubmit =async () =>{
        try {
            const tel = document.getElementById('numero_telefono').value;
            if (!tel) {
                alert('El campo de teléfono es obligatorio.');
                return; // Detener la ejecución si el teléfono está vacío
            }

            const apiUrl = `${apiConfig.apiUrl}/login_guest`;
            const response = await fetch(apiUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                nombre: document.getElementById('nombre_cliente').value,
                email: document.getElementById('email_cliente').value,
                telefono: tel,
              }),
            });
            if (response.ok) {
              // La solicitud fue exitosa, puedes manejar la respuesta como sea necesario
              const data = await response.json();
              console.log('Respuesta de la API:', data);
              
              setPhone(tel);
              // Redirigir a la siguiente página después de que la sesión se haya creado correctamente

              navigate("/search");
              
              
            } else {
                
              // La solicitud no fue exitosa, puedes manejar el error según tus necesidades
              console.error('Error al llamar a la API:', response.status, response.statusText);
            }
            
          } catch (error) {
            // Manejar errores en la llamada a la API
            console.error('Error en la llamada a la API:', error);
          }
          

    }
    

    const handleTextToSpeechCard = async (text) => {
        const url_api = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiConfig.apiKey}`;
        const audioResponse = await fetch(url_api, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            input: {
            text,
            },
            voice: {
            languageCode: 'es-US',
            ssmlGender: 'FEMALE',
            },
            audioConfig: {
            audioEncoding: 'MP3',
            },
        }),
        });
        console.log(json(audioResponse));
        if(audioResponse.ok){
            const audioData = await audioResponse.json();
            const audioUrl = audioData.audioContent;
            //setAudioUrl(audioUrl);
            playAudio(audioUrl);                        
        }
    };

    return (
        <div className=''>
            <div className="container-fluid">
                <div className="row align-items-center vh-100">
                    <div className="col-md-12 m-0 h-auto firs-bg">
                        <ModalAlert isOpen={isModalOpen} onClose={closeModal}>
                            <br/>
                            <p className='color-accent fs-08 fw-400'>Para ingresar sus datos de identificación, solo toque el cuadro de texto y luego mencione la información según corresponda: nombre completo y número de teléfono. Estos datos son importantes para que este asistente le envíe la información u otros regalos a su número con WhatsApp. De forma opcional puede ingresar manualmente su correo electrónico.</p>
                        </ModalAlert>
                        <br/>
                        

                        {/* <button className="uk-button uk-button-primary uk-button-small"
                            onClick={() => handleTextToSpeechDinamic("Digite los datos de identificación: nombre completo y número de teléfono. Estos datos son opcionales. Los resultados de búsqueda pueden ser enviados a su whatsapp o correo electrónico. para facilitar el ingreso de la información, solo haga clic o toque un cuadro de texto, luego mediante micrófono mencione su nombre, luego su numero de teléfono. También puede digitarlos mediante teclado.")}
                            ><span uk-icon="icon: question ; ratio: 2"></span>
                        </button> */}

                        <Header></Header>
                        <div className='container bg-accent-color-20 form-register'>
                            <div className='row'>
                                <div className='col-md-12 pt-3 text-center'>
                                    <h1 className='fs-12 color-accent fw-700'>Identificación 
                                        <span  className='btn' 
                                            onClick={() => {
                                                openModal();
                                                handleTextToSpeechCard("Para ingresar sus datos de identificación, solo toque el cuadro de texto y luego mencione la información según corresponda: nombre completo y número de teléfono. Estos datos son importantes para que este asistente le envíe la información u otros regalos a su número con WhatsApp. De forma opcional puede ingresar manualmente su correo electrónico.");
                                            }}
                                        >
                                            <span uk-icon="icon: question ; ratio: 1.1"></span>
                                        </span>
                                    </h1>
                                    <br/>
                                </div>
                                <div className='col-md-12 pb-3'>
                                    <div className="form-group">
                                        <label className='control-label fs-06 color-gray-70 fw-600'>Su Nombre (*):</label>
                                        <input placeholder='Toque aqui y mencione su nombre' 
                                        className='input-remake bg-accent-color-10 fs-06' 
                                        type='text' 
                                        inputMode="text"
                                        id="nombre_cliente"
                                        onFocus={(e) => startRecognition(1,e.target)}
                                        
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12 pb-3'>
                                    <div className="form-group">
                                        <label className='control-label fs-06 color-gray-70 fw-600'>Nº de WhatsApp (*):</label>
                                        <input placeholder='Toque aqui y mencione número de WhatsApp' 
                                        className='input-remake bg-accent-color-10 fs-06' 
                                        type='text' 
                                        inputMode="tel" 
                                        id="numero_telefono" 
                                        onFocus={(e) => startRecognition(2,e.target)} 
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12 pb-3' hidden>
                                    <div className="form-group">
                                        <label className='control-label fs-06 color-gray-70 fw-600'>Correo (opcional):</label>
                                        <input placeholder='Escriba su correo electrónico' 
                                        className='input-remake bg-accent-color-10 fs-06' 
                                        type='text' 
                                        inputMode="email"  
                                        id="email_cliente" 
                                        onClick={(e) => handleTextToSpeechCard("Escriba su correo electrónico.")}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <button className='btn button-remake btn-block' 
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                        >
                                        <span className='fs-08 fw-700'>Ingresar </span>
                                    </button>
                                     

                                </div>
                                <div className='col-md-12 pt-3 text-center'>
                                     
                                </div>
                            </div>
                        </div>
                        <br/>
                    <Legend></Legend>
                    </div>
                </div>
            </div>
            {/* <FloatingButton /> */}
            
        </div>
    )
}

//export default Register
export default Register;