import React from 'react';

function ModalAlert({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}><span className='color-gray-60'>x</span></button>
        
        {children}
      </div>
    </div>
  );
}

export default ModalAlert;
