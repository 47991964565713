import React, { useEffect } from 'react';

const UbicacionMapa2 = ({ lat, lng, ninhumado, jardin, namediv }) => {
  //const [selectedMarker, setSelectedMarker] = useState(null);
  const latitud = parseFloat(lat);
  const longitud = parseFloat(lng);

  useEffect(() => {
    
    console.log(ninhumado); 
    console.log(jardin); 
       
    
    const map = new window.google.maps.Map(document.getElementById(namediv), {
      center: { lat: latitud, lng: longitud },
      zoom: 18,
      mapTypeId: 'satellite',
    });


      const icon = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
      const contentPopup = `
        <div className="info-window">
          <div className="info-window-header">
            <span className="info-window-title">${jardin}</span>
          </div>
          <div className="info-window-content">
          <p>Inh: <strong>${ninhumado}</strong></p>
          </div>
        </div>
      `;

      
      const marker = new window.google.maps.Marker({
        map: map,
        icon: icon,
        position: { lat: latitud, lng: longitud },
        title: jardin,
      });     


      const infowindow = new window.google.maps.InfoWindow({
        content: contentPopup,
      });
  
      infowindow.open(map, marker); // Abrir la ventana de información automáticamente
  
      
     
 
      marker.setMap(map);
      
 

    }, [latitud, longitud, ninhumado, jardin, namediv]);


    return (
      <div id={namediv} style={{ width: '100%', height: '600px' }}></div>
    );

  };
  
  export default UbicacionMapa2;