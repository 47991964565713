import React, {useState} from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PhoneProvider } from './Components/PhoneContext';
import SlidePromotions from "./Components/SlidePromotions";
import SearchDeceased from "./Components/SearchDeceased";
import Register from "./Components/Register";
import Survey from "./Components/Survey";
import Services from "./Components/Services";
import Gift from "./Components/Gift";
import AvailableLocations from "./Components/AvailableLocations";



// function App() {
//   return (
//     <>
//     <Headers />
//     <Identificador/>
    
//     <Main/>
//     <Footer/>
//     </>
//   )
// }



//function App() {
const App = () => {
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const escucharAudio = (audioUrl) => {
    if (currentAudio) {
      currentAudio.pause();
      setCurrentAudio(null);
      setIsPlaying(false); 
    }
    if (audioUrl) {
      const audio = new Audio(`data:audio/mp3;base64,${audioUrl}`);
      audio.play();
      setCurrentAudio(audio);
      setIsPlaying(true); // Establecer isPlaying en true
    }
  };

  const detenerAudio = () => {
  if (currentAudio && isPlaying) {
      currentAudio.pause();
      setCurrentAudio(null);
      setIsPlaying(null); 
  }
  };

  return (
    <div>
      <PhoneProvider>
      <Router>
          <Routes>
            <Route path="/" exact element={<SlidePromotions/>} />
            <Route path="/search" exact element={<SearchDeceased escucharAudio={escucharAudio} detenerAudio={detenerAudio}/>} />
            <Route path="/register" exact element={<Register/>} />
            <Route path="/survey" exact element={<Survey  />} />
            <Route path="/services" exact element={<Services escucharAudio={escucharAudio} detenerAudio={detenerAudio} />} />
            <Route path="/gift" exact element={<Gift/>} />
            <Route path="/available_locations" exact element={<AvailableLocations/>} />
          </Routes>
      </Router>
      </PhoneProvider>
       
 
            
          
      {isPlaying ? (
            <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px', // Posiciona el botón en la esquina superior derecha
              }}
              onClick={detenerAudio}
            >
              Detener Audio
            </button>
        ) : (
            ""
        )}


    </div>
  )
}



export default App; 