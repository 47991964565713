import React from 'react'
import Legend from './Legend';
import Header from './Header';
import FloatingButton from './FloatingButton ';
import apiConfig from '../Config'; // Asegúrate de ajustar la ruta correcta
import { Link } from 'react-router-dom';

const Services = ({ escucharAudio,detenerAudio}) => {

    
  const startRecognition1 = (targetInput) => {
      const recognition = new window.webkitSpeechRecognition();
      recognition.lang = 'es-ES';
      
      recognition.onresult = (event) => {
        const spoken = event.results[0][0].transcript;
        targetInput.value = spoken; // Establecer el valor del input con el texto reconocido
        document.getElementById('response_chatgpt').focus();
        handleEnviarPregunta();
      };
      
      recognition.start();
      
      targetInput.onblur = () => {
        recognition.stop();

      };
  };
  

  const handleEnviarPregunta = async () => {
    try {
      const busqueda = document.getElementById('prompt_cliente').value.trim();
      const preguntaAnterior = document.getElementById('prompt_cliente_text').value;

      // Verificar si la pregunta actual es igual a la pregunta anterior
      if (busqueda === preguntaAnterior) {
        console.log('La pregunta es igual a la anterior. No se enviará la consulta.');
        return;
      }

      
      document.getElementById('prompt_cliente_text').value=busqueda;

      if (busqueda === '') {
        console.log('los datos de la pregunta entan vacios.');
        return;
      }

       // Verificar si la pregunta tiene más de 3 palabras
      const palabras = busqueda.split(' ');
      if (palabras.length < 3) {
        console.log('La pregunta debe tener al menos 3 palabras. No se enviará la consulta.');
        return;
      }

      // Verificar si la pregunta tiene más de 15 caracteres
      if (busqueda.length < 15) {
        console.log('La pregunta debe tener al menos 15 caracteres. No se enviará la consulta.');
        return;
      }


      const apiUrl = `${apiConfig.apiUrl}/prompt_chapgpt`;
      console.log(preguntaAnterior);
      console.log(busqueda);

      
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          prompt_cliente: document.getElementById('prompt_cliente').value 
         }),

      });

      if (response.ok) {
        const data = await response.json();
        let texto=data.response;
        document.getElementById('response_chatgpt').value=texto
        handleTextToSpeechCard(texto);

      } else {
        console.error('Error al guardar encuesta:', response.status);
      }
    } catch (error) {
      console.error('Error al guardar encuesta:', error);
    }
    
  };

   
  const handleTextToSpeechCard = async (text) => {
    const url_api = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiConfig.apiKey}`;
    const audioResponse = await fetch(url_api, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        input: {
          text,
        },
        voice: {
          languageCode: 'es-US',
          ssmlGender: 'FEMALE',
        },
        audioConfig: {
          audioEncoding: 'MP3',
        },
      }),
    });
      
    if(audioResponse.ok){
      const audioData = await audioResponse.json();
      const audioUrl = audioData.audioContent;
       
      escucharAudio(audioUrl);                   
    
    }
  
};

    



  return (
    <div className=''>
    <div className="container-fluid">
        <div className="row align-items-center vh-100">
            <div className="col-md-12 m-0 h-auto text-center firs-bg">
                <Header></Header>
                <p className='color-gray-70 fw-700 fs-02'>Para obtener información simplemente formule su pregunta por escrito o <br/> hable con nosotros activando el micrófono y mencionando su consulta.</p>
                <br/>
                <div className='container w-60 position-relative'>
                    <textarea type='text' className='input-remake fs-5' placeholder='Mencione su consulta'
                         inputMode="text"
                        id="prompt_cliente"
                        onFocus={(e) => startRecognition1(e.target)} // Iniciar reconocimiento al recibir el foco
                        rows="3" 
                    ></textarea>
                    <input type="hidden" id="prompt_cliente_text" />
                    <button 
                        className='button-remake fs-10' 
                        onClick={(e) => handleEnviarPregunta()}
                      >Preguntar
                    </button>
                    <br/>
                    <textarea className='w-100 response-textarea fs-06 color-gray-60'
                        id='response_chatgpt'
                        type="text"
                        inputMode="text"
                         rows="5" readOnly
                        >
                        
                    </textarea>

                    <div className='col-3 col-sm-3 col-md-12'>
                      <Link to="/available_locations">
                          <div className='box-for-text-md bg-accent-color'>
                              <h5 className='color-gray-10 fs-06'>
                              <i className="bi bi-bookmark-check p-1"></i>
                                  Productos Disponibles
                              </h5>
                          </div>
                      </Link>
                  </div>
                </div>
            <Legend></Legend>
                <br/>
                
            </div>

        </div>
    </div>
    <FloatingButton></FloatingButton>
</div>
  )
}
export default Services
