import React, { useState,useEffect,useCallback  } from 'react';
import { useNavigate } from 'react-router-dom';
import Legend from './Legend';
import Header from './Header';
import FloatingButton from './FloatingButton ';
import { usePhone } from './PhoneContext';
import apiConfig from '../Config'; // Asegúrate de ajustar la ruta correcta


const Survey = () => {
    
    const { phoneNumber } = usePhone();

    const [countdown, setCountdown] = useState(55);
    const navigate = useNavigate();
    
    const resetCountdown = useCallback(() => {
      setCountdown(55);
    }, []);

      const handleInteraction = useCallback(() => {
        resetCountdown();
      }, [resetCountdown]);


    const startRecognition = (targetInput) => {
        const recognition = new window.webkitSpeechRecognition();
        recognition.lang = 'es-ES';
        
        recognition.onresult = (event) => {
          const spoken = event.results[0][0].transcript;
          targetInput.value = spoken; // Establecer el valor del input con el texto reconocido
        };
        
        recognition.start();
        
        targetInput.onblur = () => {
          recognition.stop();
        };
      };


      const handleSubmit = async (e) => {
        e.preventDefault();
        if ( document.getElementById('sugerencias').value==="" || document.getElementById('preg1').value==="" || document.getElementById('preg2').value==="" || document.getElementById('preg3').value==="") {
          alert('Por favor, responda todas las preguntas antes de enviar el formulario.');
          return;
        }
         
        if (!phoneNumber) {
            alert('No se ha indentificado un numero de telefono.');
            return; // Detener la ejecución si el teléfono está vacío
          }

        
        try {
          
          const apiUrl = `${apiConfig.apiUrl}/save_survey`;
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              nombre: "noname",
              email: "nomail",
              telefono: phoneNumber,
              respuesta1: document.getElementById('preg1').value,
              respuesta2: document.getElementById('preg2').value,
              respuesta3: document.getElementById('preg3').value,
              comentario: document.getElementById('sugerencias').value,
             }),
    
          });
    
          if (response.ok) {
            const data = await response.json();
            console.log('Datos de búsqueda:', data);
            document.getElementById('preg1').value=""
            document.getElementById('preg2').value=""
            document.getElementById('preg3').value=""
            document.getElementById('sugerencias').value=""
            
    
          } else {
            console.error('Error al guardar encuesta:', response.status);
          }
        } catch (error) {
          console.error('Error al guardar encuesta:', error);
        }
         
    
        
      };
    
 
    useEffect(() => {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
  
      return () => clearInterval(timer);
    }, []);
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (countdown === 0) {
          navigate('/');
        }
      }, 1000);
  
      return () => clearInterval(interval);
    }, [countdown, navigate]);
  
    useEffect(() => {
      // Agrega eventos de interacción del usuario
      window.addEventListener('mousemove', handleInteraction);
      window.addEventListener('keydown', handleInteraction);
  
      return () => {
        // Limpia los eventos cuando el componente se desmonta
        window.removeEventListener('mousemove', handleInteraction);
        window.removeEventListener('keydown', handleInteraction);
      };
    }, [handleInteraction]);    

  return (
    <div className=''>
            <div className="container-fluid">
                <div className="row align-items-center vh-100">
                    <div className="col-md-12 m-0 h-auto firs-bg">
                        <Header></Header>
                        <br/>
                        <div className='container bg-accent-color-20 form-survey'>
                            <div className='row'>
                                <div className='col-md-12 pt-3 text-center'>
                                    <h1 className='fs-12 color-accent'>Encuesta</h1>
                                    <p className='fs-06 fw-300 color-gray-70'>Queremos conocer su opinión, al finalizar la encuesta, se le estara enviando un cupon de regalo, el cual puede reclamar en "Atencion al cliente".</p>
                                    <br/>
                                </div>
                                <div className='col-md-12 pb-3'>
                                    <div className="form-group">
                                        <label className='control-label fs-06 color-gray-70 fw-600'>
                                            ¿Que opina sobre las actividades realizadas este día en el Parque Memorial?
                                        </label>
                                        <select id='preg1'  className='form-control input-remake bg-accent-color-10 fs-06'>
                                            <option value="">SELECCIONE . . .</option>
                                            <option value="Regular">Regular</option>
                                            <option value="Bueno">Bueno</option>
                                            <option value="Muy bueno">Muy bueno</option>
                                            <option value="Excelente5">Excelente</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div className='col-md-12 pb-3'>
                                    <div className="form-group">
                                        <label className='control-label fs-06 color-gray-70 fw-600'>
                                            ¿Cómo calificaría la atención recibida?
                                        </label>
                                        <select id='preg2'  className='form-control  input-remake bg-accent-color-10 fs-06'>
                                            <option value="">SELECCIONE . . .</option>
                                            <option value="Mala">Mala</option>
                                            <option value="Regular">Regular</option>
                                            <option value="Buena">Buena</option>
                                            <option value="Muy buena">Muy buena</option>
                                            <option value="Excelente">Excelente</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-12 pb-3'>
                                    <div className="form-group">
                                        <label className='control-label fs-06 color-gray-70 fw-600'>
                                        ¿Estaría interesado en adquirir productos del parque memorial?
                                        </label>
                                        <select id='preg3'  className='form-control  input-remake bg-accent-color-10 fs-06'>
                                            <option value="">SELECCIONE . . .</option>
                                            <option value="No">No</option>
                                            <option value="Poco interesado">Poco interesado</option>
                                            <option value="Neutral">Neutral</option>
                                            <option value="Interesado">Interesado</option>
                                            <option value="Muy interesado">Muy interesado</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-12 pb-3'>
                                    <div className="form-group">
                                        <label className='control-label fs-06 color-gray-70 fw-600'>
                                            Mencione sugerencias para los próximos eventos.
                                        </label><br/>
                                        <textarea 
                                            type='text' 
                                            className='input-remake fs-06' 
                                            placeholder='Ingrese sugerencia'
                                            inputMode="text"
                                            id="sugerencias"  
                                            onFocus={(e) => startRecognition(e.target)} // Iniciar reconocimiento al recibir el foco
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <button className='btn button-remake btn-block' onClick={() => handleSubmit()}><span className='fs-08'>Enviar</span></button>
                                    
                                </div>
                            </div>
                        </div>
                        <br/>
                    <Legend></Legend>
                    </div>
                </div>
            </div>
            <FloatingButton />
        </div>
  )
}

export default Survey